.locationBtn {
    background: #cc4141;
    padding: 8px 20px;
    cursor: pointer;
    border-radius: 5px;
    width: 13rem;
    color: white;
    display: inline-flex;
    justify-content: center;
}

.btnContainer {
    display: flex;
    justify-content: space-evenly;
}

.team-row {
    background-color: #f7f7f7;
    border-bottom: 1px solid white
}

.team-header {
    background-color: #ededed;
    border-bottom: 1px solid white;
}

.status-square {
    width:15px;
    height:15px;
}

.status-internal {
    background-color: #293537
}

.status-booked {
    background-color: #01b8aa;
}

.status-leave {
    background-color: #cc4141;
}
