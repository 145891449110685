@tailwind base;
@tailwind components;
@tailwind utilities;

select {
    color: black !important;
}

.spinner {
  @apply animate-spin inline-block w-24 h-24 border-8 rounded-full;
  border-top-color: transparent;
}
