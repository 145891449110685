.app-layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
}

.main-layout {
  background-image: url("assets/images/background.png");
}

.admin-layout {
  background-color: #2b2a2f;
}