.sophie-greeting {
  color: black;
}

.sophie-greeting .user-name {
  @apply text-3xl;

  font-weight: 300;
  color: #cc4141;
}

.sophie-greeting .message-text {
  @apply text-xl;

  white-space: pre-wrap;
  font-weight: 400;
}
