.twitter-widget {
  display: flex;
  flex-direction: row;
}

.twitter-tweet {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1880px) {
  .twitter-widget {
    padding-left: 40px;
  }
}
