footer {
    color: #9e9e9e;
    font-size: 0.75rem;
    position: relative;
    width: 100%;
}

.footer-link {
    display: inline-block;
    line-height: initial;
}