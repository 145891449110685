.appointment-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.appointment-table tr {
  text-align: start;
}

.appointments {
  padding-left: 100px;
  padding-top: 15px;
}

.appointment-container {
  min-height: 120px;
  max-height: 50rem;
  overflow: auto;
}

.appointment-time {
  min-width: 100px;
}

@media (min-width: 768px) {
  .appointment-time {
    min-width: 182px;
  }
}

.teams-icon {
  height: 1.5rem;
  width: 1.8rem;
}

.bg-red0 {
  background-color: #d88181;
}

.action-btn {
  width: 9rem
}

.teams-btn {
  background-color: #6264a7
}

.calendar-arrow {
  font-size: 2rem;
  color: #cc4141;
}

.d-inline-flex{
 display: inline-flex;
}

.icon-size{
  font-size: 1.5vw;
}

