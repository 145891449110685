@font-face {
  font-family: "Open Sans", Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body,
#root,
.App {
  height: 100%;
}

.widget-grid-container {
  display: grid;
  grid-template-columns: auto auto;
}

.widget-item {
  width: auto;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, #eeeeee, #d8d8d8);
  color: #000000;
  cursor: grab;
}

.draggable:active{
  cursor: grabbing;
}

.grab {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grab:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

body {
 -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans", Arial, sans-serif;
}

/* ---- reset ---- */
.particles-js-canvas-el{
  filter: blur(10px);
  position: fixed;
  z-index: -999;
}
body {
  height:100%;
  margin: 0;
  /* font: normal 75% Arial, Helvetica, sans-serif; */
}
/* ---- particles.js container ---- */
#particles-js {
  position: fixed;
    z-index: -999;
  width: 100%;
  height: 100%;
  background-color: #9b2626;
  /* background-image: url(""); */
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 50% 50%; */
} /* ---- stats.js ---- */
.count-particles {
  background: #000022;
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  color: #13e8e9;
  font-size: 0.8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.js-count-particles {
  font-size: 1.1em;
}
#stats,
.count-particles {
  -webkit-user-select: none;
  margin-top: 5px;
  margin-left: 5px;
}
#stats {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}
.count-particles {
  border-radius: 0 0 3px 3px;
}


/* react-toastify style override */
:root {
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #cc4141;
}
