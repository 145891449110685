.mt-3px {
  margin-top: 3px;
}

.shadow-1 {
  box-shadow: -4px 8px 17px 0 rgba(0, 0, 0, 0.2);
}

.bg-committed {
  background-color: #aa71ff;
}

.bg-new {
  background-color: #8d9f9b;
}

.bg-inprogress {
  background-color: #fdae33;
}

.bg-done {
  background-color: #62bb35;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #cc4141;
}

input:focus + .slider {
  box-shadow: 0 0 1px #cc4141;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}