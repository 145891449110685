#speech.btn {
  border: none;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  font-size: 1.5em;
  color: #fff;
  padding: 0;
  margin: 0;
  background: #CC4141;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
}

.static-ring {
  content: '';
  width: 60px;
  height: 60px;
  position: absolute;
  top: 10px;
  left: 20px;
  pointer-events: none;
}

.pulse-ring {
  border: 5px solid #CC4141;
  border-radius: 50%;
  animation: pulsate infinite 2s;
}

@keyframes pulsate {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.listening-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  height: 80px;
}