.menu-container {
  position: absolute;
  top: 5rem;
  right: 0;
  display: flex;
  flex-direction: column;
}

.menu-main-container {
  @apply py-6 sm:py-4 px-8 sm:px-6 rounded-sm shadow-xl;
  display: flex;
  background-color: white;
  color: #000000;
}

.menu-sub-container {
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.61);
}

.menu-button {
  @apply bg-red1 text-white2;
  padding: 0.5rem 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.line {
  @apply mx-8 sm:mx-4;
  width: 1px;
  height: 4rem;
  border: solid 1px #979797;
}

.button-icon {
  @apply h-8 sm:h-6 mx-auto;
}