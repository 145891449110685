.weather-icon {
  height: 75px;
  width: 75px !important;
  margin: 0px 0px 0px -7px !important;
  vertical-align: middle;
  display: inline;
}

.temperature {
  @apply text-xl; 
  
  vertical-align: middle;
  color: black;
}

.min-max-temp {
  font-size: 20px;
}

.forecast-day {
  margin-bottom: 2%;
}

.forecast-day p {
  @apply text-xl;
  
  margin-bottom: 0;
}