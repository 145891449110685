.user-section_header {
  @apply bg-red1 text-white2;

  display: flex;
  align-items: center;
  padding: 1rem 2rem;
}

.user-section_content {
  @apply bg-white2;

  padding: 2rem;
}

.user-section_input-label {
  @apply text-xl;
  
  font-weight: bold;
}

.user-section_input {
  @apply text-xl;
  
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: left;
  font-size: 1.125rem;
  border: 1px solid black;
  border-radius: 0.25rem;
}

.user-section_btn {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  font-size: 1.125rem;
  border-radius: 0.25rem;
}

.user-section_btn > svg {
  height: 1.125rem;
  margin-right: 0.5rem;
}

.user-section_icon-btn > svg {
  height: 1.75rem;
}

button.btn-dark-grey {
  @apply text-white2;

  background-color: #777;
}