.admin > * {
  color: white;
  text-align: left;
}

.admin > p {
  margin-top: 20px;
}

.admin > * > input {
  margin-right: 15px;
}

.admin > * > button {
  margin-top: 10px;
}
