.bot-textfield {
  color: #000000;
  border-color: #cccc;
  padding: 10px 25px;
  border-radius: 50px;
  border-width: 1px;
  font-size: large;
  margin: 15px 0px 15px 15px;
  width: 100%;
}

.bot-textfield:focus {
  outline: 0;
  -webkit-box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(204, 65, 65, 0.6);
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(204, 65, 65, 0.6);
}

.bot-controls {
  display: flex;
  flex-direction: row;
  height: 75px;
  width: 100%;

  border-top-color: #cccccc;
  border-top-style: solid;
  border-top-width: 1px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 40px;
}
.lds-ellipsis div {
  position: absolute;
  top: 16px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #666666;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 16px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 28px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 40px;
  animation: lds-ellipsis3 0.6s infinite;
}

/*Overwritten by telwind.css: overwriting the default link color with ac-anchor class*/
a.ac-anchor {
  color: #CC4141;
}

/*It is broken image that puts a break line*/
img.webchat__markdown__external-link-icon {
  display: none;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
