.logo {
  margin-right: 15px;
  margin-bottom: 15px;
}

.login-textfield {
  color: #000000;
  font-size: medium;
  top: 11.7rem;
  height: 13rem;
  right: 0;
  text-align: left;
  /* -webkit-backdrop-filter: blur(10px); */
  /* backdrop-filter: blur(3px); */
  background-color: rgba(0, 0, 0, 0.61);
}

.typeahead-user-text-input{
  width: 100%;
  background-color: #0f0f0f;
  height: 2.3rem;
  color: #a0acbe;
  padding-left: 1rem;
  background-image: url('~assets/images/gridicons-search.png');
  background-position: right 1rem center;
  background-repeat: no-repeat;
}

.typeahead-user-list {
  color: #FFF;
}

.typeahead-user-list-item{
  display:flex;
  margin-top: 0.5rem;
  padding-left: 2rem;
  background-image: url('~assets/images/icon-shape.png'), url('~assets/images/switch-user-options.png');
  background-position: left, right;
  background-repeat: no-repeat no-repeat;
  background-size: auto, contain;
  height: 2.3rem;
}

.typeahead-user-list-item-login{
  display:flex;
  margin-top: 0.5rem;
  padding-left: 2rem;
  background-image: url('~assets/images/icon-shape.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: auto;
  height: 2.3rem;
}

.typeahead-option {
  margin: auto 0 auto 0;
}

.logout-button {
  color: red;
}

.header-date-time {
  font-weight: 300;
  color: #fff;
  font-size: 20px;
}

.header-icon {
  display: flex;
  align-items: center;
}

.header-icon span {
  color: white;
  margin-left: 5px;
}
.header-icon img {
  margin-right: 10px;
  position: relative;
  top: -2px;
}

.main-header {
  background-color: #000000;
  min-height: 80px;
}

.header-time {
  margin-left: 20px;
}

.menu-content {
  background-color: white;
  color: #000000;
  height: 7rem
}

.qrCode {
  float:right;
  height: 8rem
}

.widget-icon-background{
  top: 1.4rem
}