.input-field-container {
  display: flex;
  flex: 1;
  align-items: center;
  height: fit-content;
  user-select: none;
  background-color: theme('colors.white1');
  border-top-left-radius: 0.25rem; /* 4px */
  border-bottom-left-radius: 0.25rem; /* 4px */
  overflow: hidden;
}

.input-field {
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: left;
  font-size: 1.125rem;
  color: inherit;
  background-color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-field::placeholder {
  color: theme('colors.grey1');
}

.input-message {
  padding: 0.5rem 0.5rem 0 0.5rem;
  text-align: left;
}

.input-message-success {
  color: green;
}

.input-message-failure {
  color: red;
}

.input-button {
  height: fit-content;
  padding: 0.5rem 1.5rem;
  font-size: 1.125rem;
  border-top-right-radius: 0.25rem; /* 4px */
  border-bottom-right-radius: 0.25rem; /* 4px */
}

.facial-recognition-button {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  border-radius: 0.25rem; /* 4px */
}

.input-button:disabled, .facial-recognition-button:disabled {
  cursor: no-drop;
}